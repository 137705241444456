import React from 'react';

const Footer = () => {
    return(
        <div className="footerContainer">
            <p> © Portfolio produit sans template avec React et SCSS</p>
        </div>
    )
}

export default Footer;